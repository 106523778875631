@use '@/scss/underscore' as _;
@use '@/scss/dimensions' as dim;

.wrapper {
  width: 100%;
}

.navigationAnchor {
  position: absolute;
  inset: _.unit(6) auto _.unit(6) _.unit(6);
}

.navigation {
  position: sticky;
  top: _.unit(6);
  flex-shrink: 0;
  margin-right: _.unit(7.5);
  width: 220px;

  > :not(:last-child) {
    margin-bottom: _.unit(6);
  }
}

.content {
  width: 100%;
  min-width: dim.$guide-content-min-width;
  max-width: dim.$guide-content-max-width;
  padding: dim.$guide-content-padding calc(dim.$guide-sidebar-width + dim.$guide-panel-gap + dim.$guide-content-padding);
  margin: 0 auto;
  position: relative;

  > :not(:last-child) {
    margin-bottom: _.unit(6);
  }

  &.compact {
    min-width: 652px;
    padding: 0;
  }
}

.stepper {
  font: var(--font-title-2);
  color: var(--color-text);
  border-radius: 12px;
  border: 1px solid var(--color-surface-5);
  padding: _.unit(3) _.unit(4);
  user-select: none;
  cursor: pointer;

  &:hover {
    background: var(--color-surface-5);
  }

  &.active {
    background: var(--color-focused-variant);
  }
}

@media screen and (max-width: dim.$guide-content-max-width) {
  .content {
    margin: 0;
    padding-right: dim.$guide-content-padding;
    max-width: calc(dim.$guide-main-content-max-width + dim.$guide-sidebar-width + dim.$guide-panel-gap + 2 * dim.$guide-content-padding);
  }
}
